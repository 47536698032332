import React from 'react';
import { string } from 'prop-types';
import classNames from 'classnames';

import css from './ProfileIconCard.module.css';

const LIKE = 'like';
const TWEET = "tweet";

const ProfileIconCard = props => {
  const { className, rootClassName, type } = props;
  const classes = classNames(rootClassName || css.root, className);

  switch (type) {
    case LIKE:
      return (
        <svg fill="#000000" width="15px" height="15px" viewBox="0 0 0.975 0.975" enable-background="new 0 0 52 52" space="preserve"><g><path d="M0.197 0.394h-0.094c-0.015 0 -0.028 0.013 -0.028 0.028v0.431c0 0.015 0.013 0.028 0.028 0.028H0.15c0.041 0 0.075 -0.034 0.075 -0.075V0.422c0 -0.015 -0.013 -0.028 -0.028 -0.028z" /><path d="M0.825 0.412h-0.112c-0.041 0 -0.075 -0.034 -0.075 -0.075V0.15c0 -0.041 -0.034 -0.075 -0.075 -0.075h-0.047c-0.015 0 -0.028 0.013 -0.028 0.028v0.112c0 0.099 -0.069 0.197 -0.159 0.197 -0.015 0 -0.028 0.013 -0.028 0.028v0.375c0 0.015 0.011 0.028 0.026 0.028 0.128 0.006 0.171 0.056 0.304 0.056 0.141 0 0.27 -0.015 0.27 -0.178V0.487c0 -0.041 -0.034 -0.075 -0.075 -0.075z" /></g></svg>
      );
    case TWEET:
      return (
        <svg width="11px" height="11px" viewBox="0 0 0.33 0.33" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.114 0.289c0.104 0 0.16 -0.09 0.16 -0.168 0 -0.003 0 -0.005 0 -0.008A0.121 0.121 0 0 0 0.302 0.083a0.111 0.111 0 0 1 -0.032 0.009 0.059 0.059 0 0 0 0.025 -0.033 0.108 0.108 0 0 1 -0.036 0.014c-0.021 -0.024 -0.057 -0.025 -0.08 -0.003 -0.015 0.014 -0.021 0.036 -0.016 0.056 -0.045 -0.002 -0.088 -0.025 -0.116 -0.062 -0.015 0.027 -0.007 0.061 0.017 0.079A0.053 0.053 0 0 1 0.038 0.137v0.001c0 0.028 0.019 0.052 0.045 0.058 -0.008 0.002 -0.017 0.003 -0.026 0.001 0.007 0.024 0.029 0.041 0.053 0.041a0.11 0.11 0 0 1 -0.07 0.025c-0.005 0 -0.009 0 -0.013 -0.001A0.154 0.154 0 0 0 0.114 0.289Z" stroke="#fff" stroke-width="0.0275" stroke-linecap="round" stroke-linejoin="round" /></svg>
      );
    default:
      return (
        <svg
          className={classes}
          width="29"
          height="19"
          viewBox="0 0 29 19"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M26.58 19H2.42A2.4004 2.4004 0 0 1 0 16.62V2.38A2.4 2.4 0 0 1 2.42 0h24.16A2.4004 2.4004 0 0 1 29 2.38v14.25c-.0165 1.3216-1.0984 2.3811-2.42 2.37zM10 5.83c0-.46-.35-.83-.78-.83H3.78c-.43 0-.78.37-.78.83v3.34c0 .46.35.83.78.83h5.44c.43 0 .78-.37.78-.83V5.83z"
              fill="#DADDE2"
            />
            <path
              d="M25 15h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0h-3c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1zm-6 0H4c-.65 0-1-.3-1-1s.35-1 1-1h3c.65 0 1 .3 1 1s-.35 1-1 1z"
              fill="#B2B6C1"
            />
          </g>
        </svg>
      );
  }
};

ProfileIconCard.defaultProps = {
  className: null,
  rootClassName: null,
  type: 'default',
};

ProfileIconCard.propTypes = {
  className: string,
  rootClassName: string,
  type: string,
};

export default ProfileIconCard;
