import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import { intlShape, injectIntl, useIntl } from '../../../util/reactIntl';
import { isMainSearchTypeKeywords } from '../../../util/search';

import { Form, LocationAutocompleteInput } from '../../../components';

import IconSearchDesktop from './IconSearchDesktop';
import css from './TopbarSearchForm.module.css';
import { useConfiguration } from '../../../context/configurationContext';
import { useRouteConfiguration } from '../../../context/routeConfigurationContext';
import { useHistory, withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { parse } from '../../../util/urlHelpers';
import { createResourceLocatorString } from '../../../util/routes';

const identity = v => v;

const KeywordSearchField = props => {
  const { keywordSearchWrapperClasses, iconClass, intl, isMobile, inputRef } = props;
  return (
    <div className={keywordSearchWrapperClasses}>
      <button className={css.searchSubmit}>
        <div className={iconClass}>
          <IconSearchDesktop />
        </div>
      </button>
      <Field
        name="keywords"
        render={({ input, meta }) => {
          return (
            <input
              className={isMobile ? css.mobileInput : css.desktopInput}
              {...input}
              id={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
              data-testid={isMobile ? 'keyword-search-mobile' : 'keyword-search'}
              ref={inputRef}
              type="text"
              placeholder={intl.formatMessage({
                id: 'TopbarSearchForm.placeholder',
              })}
              autoComplete="off"
            />
          );
        }}
      />
    </div>
  );
};

const LocationSearchField = props => {
  const { desktopInputRootClass, intl, isMobile, inputRef, onLocationChange } = props;
  return (
    <Field
      name="location"
      format={identity}
      render={({ input, meta }) => {
        const { onChange, ...restInput } = input;

        // Merge the standard onChange function with custom behaviur. A better solution would
        // be to use the FormSpy component from Final Form and pass this.onChange to the
        // onChange prop but that breaks due to insufficient subscription handling.
        // See: https://github.com/final-form/react-final-form/issues/159
        const searchOnChange = value => {
          onChange(value);
          onLocationChange(value);
        };

        return (
          <LocationAutocompleteInput
            className={isMobile ? css.mobileInputRoot : css.desktopInputRootClass}
            iconClassName={isMobile ? css.mobileIcon : css.desktopIcon}
            inputClassName={isMobile ? css.mobileInput : css.desktopInput}
            predictionsClassName={isMobile ? css.mobilePredictions : css.desktopPredictions}
            predictionsAttributionClassName={isMobile ? css.mobilePredictionsAttribution : null}
            placeholder={intl.formatMessage({ id: 'TopbarSearchLocationForm.placeholder' })}
            closeOnBlur={!isMobile}
            inputRef={inputRef}
            input={{ ...restInput, onChange: searchOnChange }}
            meta={meta}
          />
        );
      }}
    />
  );
};

class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    // onChange is used for location search
    this.onChange = this.onChange.bind(this);
    // onSubmit is used for keywords search
    this.onSubmit = this.onSubmit.bind(this);
    this.customSubmit = this.customSubmit.bind(this);

    // Callback ref
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };
  }

  onChange(location) {
    const { appConfig, onSubmit } = this.props;
    if (!isMainSearchTypeKeywords(appConfig) && location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      onSubmit({ location });
      // blur search input to hide software keyboard
      this.searchInput?.blur();
    }
  }

  onSubmit(values) {
    const { appConfig, onSubmit } = this.props;
    if (isMainSearchTypeKeywords(appConfig)) {
      onSubmit({ keywords: values.keywords });
      // blur search input to hide software keyboard
      this.searchInput?.blur();
    }
  }

  customSubmit(values) {
    if (values && values.keywords && values.keywords.trim()) {
      const { history, routeConfiguration, location } = this.props;
      const pastSearchValues = location && location.search && parse(location.search, {
        latlng: ['origin'],
        latlngBounds: ['bounds'],
      });
      const searchParams = pastSearchValues ? { ...pastSearchValues, keywords: values.keywords } : { keywords: values.keywords };
      return history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, searchParams));
    }
  }

  render() {
    const { onSubmit, appConfig, location, ...restOfProps } = this.props;
    const isKeywordsSearch = isMainSearchTypeKeywords(appConfig);
    const submit = isKeywordsSearch ? this.onSubmit : onSubmit;

    return (
      <FinalForm
        {...restOfProps}
        onSubmit={submit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            handleSubmit,
            form,
            values
          } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          // Location search: allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();
          const submitFormFn = isKeywordsSearch ? handleSubmit : preventFormSubmit;

          const keywordSearchWrapperClasses = classNames(
            css.keywordSearchWrapper,
            isMobile ? css.mobileInputRoot : desktopInputRootClass
          );

          return (
            <Form className={classes} onSubmit={submitFormFn} enforcePagePreloadFor="SearchPage">
              {isKeywordsSearch ? (

                <>
                  <KeywordSearchField
                    keywordSearchWrapperClasses={keywordSearchWrapperClasses}
                    iconClass={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}
                    intl={intl}
                    isMobile={isMobile}
                    inputRef={this.setSearchInputRef}
                  />
                  <LocationSearchField
                    desktopInputRootClass={desktopInputRootClass}
                    intl={intl}
                    isMobile={isMobile}
                    inputRef={this.setSearchInputRef}
                    onLocationChange={this.onChange}
                  />
                </>
              ) : (
                <>
                  <div className={css.topbarSearchWrapper}>
                    <div className={css.keywordSearchInput}>
                      <input
                        className={css.searchBox}
                        type="text"
                        name="keywords"
                        onChange={(e) => {
                          form.change("keywords", e.currentTarget.value)
                        }}
                        onBlur={(e) => {
                          this.customSubmit(values)
                        }}
                        placeholder={intl.formatMessage({
                          id: 'TopbarSearchForm.placeholder',
                        })}
                        autoComplete="off"
                        onKeyPress={(e) => {
                          if (e.key === 'Enter') {
                            this.customSubmit(values);
                          }
                        }}
                      />
                    </div>
                    <div>
                      <LocationSearchField
                      desktopInputRootClass={desktopInputRootClass}
                      intl={intl}
                      isMobile={isMobile}
                      inputRef={this.setSearchInputRef}
                      onLocationChange={this.onChange}
                    />
                    </div>

                  </div>
                </>
              )}
            </Form>
          );
        }}
      />
    );
  }
}

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  appConfig: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};


const TopbarSearchForm = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  return (
    <TopbarSearchFormComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      {...props}
    />
  );
};

export default compose(
  withRouter,
)(TopbarSearchForm);;
